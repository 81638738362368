<template>
  <div class="template-reserve-list">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="header-title">All Reserves</h4>
            <router-link to="/admin/reserveAdd">
              <b-button
                variant="success"
                size="sm"
                class="ml-1 btn-add-reserve"
                v-if="['webadmin', 'zoneadmin'].includes(this.currentRole)"
              >
                <i class="mdi mdi-account-plus mr-1"></i>
                <span>Add a Reserve</span>
              </b-button>
            </router-link>
            <p class="text-muted font-13 mb-4"></p>
            <div class="row mb-md-2">
              <!-- Filters -->
              <div class="col-12">
                <!-- Filter by Zone -->
                <b-form-select
                  v-model="selectedZone"
                  class="col-sm-3 mb-3 mr-2"
                  v-if="['webadmin'].includes(this.currentRole)"
                >
                  <b-form-select-option :value="null">
                    Filter by Zone
                  </b-form-select-option>
                  <template v-for="item in zoneTable">
                    <b-form-select-option :key="item.id" :value="item.id">
                      {{ item.name }}
                    </b-form-select-option>
                  </template>
                </b-form-select>

                <!-- Filter by Vendor -->
                <b-form-select
                  v-model="selectedOperator"
                  class="col-sm-3 mb-3 mr-2"
                >
                  <b-form-select-option :value="null">
                    Filter by Operators
                  </b-form-select-option>
                  <template v-for="item in filteredOperatorsTable">
                    <b-form-select-option
                      :key="item.operator_id"
                      :value="item.operator_id"
                    >
                      {{ item.company_name }}
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </div>
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="reservesData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
              >
                <template v-slot:cell(equipment_model)="row">
                  {{ row.value.brand }}
                  {{ row.value.name }}
                  <span>
                    {{ row.item.serial_no }}
                  </span>
                </template>

                <template v-slot:cell(operator)="row">
                  {{ row.value.company_name }}
                  <span>
                    {{ row.item.operator.zone.name }}
                  </span>
                </template>

                <template v-slot:cell(noFlights)> - </template>

                <template v-slot:cell(actions)="row">
                  <a
                    title="View"
                    class="action-icon view"
                    @click="displayReserveProfile(row.item)"
                  >
                    <i class="mdi mdi-eye"></i>
                  </a>

                  <a
                    title="Edit"
                    class="action-icon edit"
                    @click="editRow(row.item)"
                  >
                    <i class="mdi mdi-square-edit-outline"></i>
                  </a>
                  <a
                    title="Delete"
                    class="action-icon delete"
                    @click="deleteRow(row.item)"
                  >
                    <i class="mdi mdi-delete"></i>
                  </a>
                </template>

                <template v-slot:row-details="row">
                  <b-card>
                    <ul>
                      <li v-for="(value, key) in row.item" :key="key">
                        {{ key }}: {{ value }}
                      </li>
                    </ul>
                  </b-card>
                </template>
              </b-table>
            </div>
            <div class="row">
              <!-- # of entries per page selector -->
              <div
                class="col-12 col-sm-6 text-center text-sm-left mb-2 mb-sm-0"
              >
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                      class="ml-2 mr-2"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="dataTables_paginate paging_simple_numbers">
                  <b-pagination
                    v-model="currentPage"
                    class="justify-content-center justify-content-sm-end"
                    :total-rows="rows"
                    :per-page="perPage"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </div>
</template>

<script>
import {
  GET_OPERATORS,
  GET_ZONES,
  GET_RESERVES,
} from "@/graphql/queries/queries";
import { DELETE_RESERVE } from "@/graphql/mutations/mutations";
import { mapState } from "vuex";

const OPERATORS_ATTRIBUTE = "operators";
const ZONES_ATTRIBUTE = "zone";
const RESERVES_ATTRIBUTE = "reserves";

export default {
  name: "index",
  data() {
    return {
      selectedZone: null,
      selectedOperator: null,
      reservesTable: [],
      reservesData: [],
      operatorsTable: {},
      filteredOperatorsTable: {},
      zoneTable: {},
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      fields: [
        {
          key: "equipment_model",
          label: "Reserves",
          class: "wing",
          sortable: true,
        },
        { key: "operator", label: "Owner", class: "owner", sortable: true },
        { key: "max_load", tdClass: "Max Load", sortable: true },
        { key: "noFlights", label: "No of Flights", sortable: true },
        { key: "actions", label: "Actions", thclass: "action-field" },
      ],
    };
  },
  beforeDestroy: function () {
    this.$apollo.provider.defaultClient.cache.reset();
  },
  computed: {
    /**
     * Total no. of records
     */
    ...mapState(["currentRole"]),
    rows() {
      return this.reservesTable.length;
    },
  },
  mounted() {
    this.totalRows = this.reservesTable.length;
  },
  watch: {
    reservesTable() {
      this.reservesData = this.reservesTable;
    },
    selectedZone() {
      this.filteredOperatorsTable = this.operatorsTable;
      this.filterList();
    },
    selectedOperator() {
      this.filterList();
    },
    operatorsTable() {
      this.filteredOperatorsTable = this.operatorsTable;
    },
  },
  methods: {
    filterList() {
      this.reservesData = this.reservesTable;
      if (this.selectedZone !== null) {
        this.filteredOperatorsTable = this.operatorsTable.filter(
          (a) => a.zone.id === this.selectedZone
        );
        this.reservesData = this.reservesData.filter(
          (a) => a.operator.zone.id === this.selectedZone
        );
      }
      if (this.selectedOperator !== null) {
        this.reservesData = this.reservesData.filter(
          (a) => a.operator.operator_id === this.selectedOperator
        );
      }
      if (this.selectedZone !== null && this.selectedOperator !== null) {
        this.reservesData = this.reservesData.filter(
          (a) =>
            a.operator.zone.id === this.selectedZone &&
            a.operator.operator_id === this.selectedOperator
        );
      }
    },
    async deleteRow(item) {
      try {
        const confirmDelete = await this.$bvModal.msgBoxConfirm(
          "Are you sure to delete this Reserve with serial no" +
            " " +
            item.serial_no +
            "?"
        );
        if (confirmDelete) {
          const deleteMutation = await this.$apollo.mutate({
            mutation: DELETE_RESERVE,
            variables: {
              pk: item.id,
            },
          });

          if (deleteMutation) {
            if (deleteMutation.data.delete_reserves_by_pk.id) {
              console.log(
                "item delete successfully with id =",
                deleteMutation.data.delete_reserves_by_pk.id
              );
              this.$apollo.queries.reservesTable.refetch();
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    displayReserveProfile(rowItem) {
      this.$router.push({
        name: "reserveProfile",
        params: { reserveID: rowItem.id },
      });
    },
    editRow(item) {
      this.$router.push({
        name: "reserveAdd",
        params: { reserveID: item.id },
      });
    },
  },
  apollo: {
    reservesTable: {
      // graphql query
      query: GET_RESERVES,
      update(data) {
        return data[RESERVES_ATTRIBUTE];
      },
      error(error) {
        this.error = JSON.stringify(error.message);
        console.error(this.error);
      },
    },
    operatorsTable: {
      // graphql query
      query: GET_OPERATORS,
      update(data) {
        return data[OPERATORS_ATTRIBUTE];
      },
      error(error) {
        this.error = JSON.stringify(error.message);
        console.error(this.error);
      },
    },
    zoneTable: {
      // graphql query
      query: GET_ZONES,
      update(data) {
        return data[ZONES_ATTRIBUTE];
      },
      error(error) {
        this.error = JSON.stringify(error.message);
        console.error(this.error);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/sass_files";

.template-reserve-list {
  padding-top: 24px;

  table.table {
    color: #6c757d;
  }

  td.wing span,
  td.owner span {
    display: block;
    font-size: 13px;
    color: #98a6ad;
    font-weight: 400;
  }

  .action-icon {
    display: inherit;
    padding: 0 5px;
    font-size: 1.2rem;
    color: #98a6ad;
    cursor: pointer;
  }

  .action-icon:hover {
    color: #6c757d;
  }
  .table .action-field {
    max-width: 2rem !important;
  }
  h4.header-title {
    display: inline-block;
    vertical-align: middle;
    margin: 0 30px 0 0;
  }
}
</style>
